import React from "react"

import Button from "../components/button"
import SectionHeader from "./section-header"

const CallToAction = () => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="Already have an account?"
      description="Start using the app now. Help research and healthcare."
    />
    <Button>Sign into the application</Button>
  </div>
)

export default CallToAction
